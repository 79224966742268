<template>
    <div class="auth-layout">
        <div class="auth-form">
            <div class="title">实名认证</div>
            <div class="cont">
                <el-form :model="formInfo.form" :label-width="120" :rules="formInfo.rules" ref="form" size="large">
                    <el-form-item label="姓名" prop="real_name">
                        <el-input v-model="formInfo.form.real_name" />
                    </el-form-item>
                    <el-form-item label="身份证号码" prop="idcard_number">
                        <el-input v-model="formInfo.form.idcard_number" />
                    </el-form-item>
                    <el-form-item label="身份证上传">
                        <el-upload :action="upload_url" :headers="headers" :limit="1" :on-success="handleImgSuccessFront" :show-file-list="false" style="height:120px;margin-right:8px;">
                            <div class="identCardUpload">
                                <el-image class="pic" style="width:120px;height:120px;" fit="contain" :src="formInfo.form.idcard_front_image" lazy>
                                    <template #error>
                                         <div class="info">
                                            <img src="/static/images/icon-add.png" alt="">
                                            <span>身份证正面上传</span>
                                         </div>
                                    </template>
                                </el-image>
                            </div>
                        </el-upload>
                        <el-upload :action="upload_url" :headers="headers" :limit="1" :on-success="handleImgSuccessBack" :show-file-list="false" style="height:120px;">
                            <div class="identCardUpload">
                                <el-image class="pic" style="width:120px;height:120px;" fit="contain" :src="formInfo.form.idcard_back_image" lazy>
                                    <template #error>
                                         <div class="info">
                                            <img src="/static/images/icon-add.png" alt="">
                                            <span>身份证背面上传</span>
                                         </div>
                                    </template>
                                </el-image>
                            </div>
                        </el-upload>
                        <div class="example">
                            <img src="/static/images/card01.png" alt="">
                            <img src="/static/images/card02.png" alt="">
                        </div>
                    </el-form-item>
                    <el-form-item label="性别" prop="gender">
                        <el-input v-model="formInfo.form.gender" disabled />
                    </el-form-item>
                    <el-form-item label="民族" prop="ethnicity">
                        <el-input v-model="formInfo.form.ethnicity" disabled/>
                    </el-form-item>
                    <el-form-item label="出生日期" prop="birth_date">
                        <el-input v-model="formInfo.form.birth_date" disabled/>
                    </el-form-item>
                    <el-form-item label="户籍地址" prop="idcard_address">
                        <el-input v-model="formInfo.form.idcard_address" disabled/>
                    </el-form-item>
                    <el-form-item label="身份证有效期" prop="idcard_validity_date">
                        <el-input v-model="formInfo.form.idcard_validity_date" disabled/>
                    </el-form-item>
                    <div class="btn-submit" @click="onSubmit">提交认证</div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
//import user from "@/api/user";
import attachment from "@/api/public";
import {getToken} from "@/utils/auth";
export default{
    name:'personAuth',
    components: {},
    data() {
        const validateFrontImage=(rule,value,callback)=>{
        if(this.formInfo.form.idcard_front_image == ''){
            callback(new Error('请上传身份证正面照'))
        }else{
            callback()
        }
    }
        return {
            upload_url: '',
            headers: {"Authorization": getToken()},
            formInfo:{
                form:{
                    real_name:'',
                    idcard_number:'',
                    idcard_front_image:'',
                    idcard_back_image:''
                },
                rules:{
                    real_name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
                    idcard_number: [{required: true, message: '请输入身份证号码', trigger: 'blur'}],
                    idcard_front_image:[{required:true,validator:validateFrontImage,trigger:'change'}]
                }
            }
        }
  },
  created() {
    attachment.uploadFileUrl().then(res => {
      this.upload_url = res;
      console.log('@@',this.upload_url,this.headers)
    });
  },
  methods: {
    
    // 正面身份证照片上传成功
    handleImgSuccessFront(res) {
      console.log('上传成功',res);
      if (res.code == 1) {
        this.$message.error({
          message: res.msg
        });
      } else {
        this.formInfo.form.idcard_front_image = res.data.url;
        this.$message.success({
          message: '照片文件成功！'
        });
      }
    },
    // 背面身份证照片上传成功
    handleImgSuccessBack(res) {
      console.log('上传成功',res);
      if (res.code == 1) {
        this.$message.error({
          message: res.msg
        });
      } else {
        this.formInfo.form.idcard_back_image = res.data.url;
        this.$message.success({
          message: '照片文件成功！'
        });
      }
    },
    // 提交认证信息
    async onSubmit() {
      this.$refs['form'].validate((valid) => {
        console.log('valid',valid)
        // if (valid) {
        //     user.personalAuth(this.formInfo.form).then(res => {
        //     console.log('res',res)
        //   });
        // } else {
        //   return false
        // }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.auth-layout{padding:60px 0;background:#fff;font-family: Source Han Sans;}
.auth-form{width:560px;margin:0 auto; 
   .title{font-size: 20px;font-weight: bold;line-height: 26px;margin-bottom:40px;text-align: center;color: #333333;}
   .el-form-item--large{margin-bottom:45px;}
}
.identCardUpload{width:122px;height: 122px;border: 1px dashed #DCDCDC;
    .info{width:120px;height: 120px;display: flex;flex-direction: column;align-items: center;justify-content: center;background: #EEEEEE;
        img{margin-bottom:5px;}
        span{color: rgba(0, 0, 0, 0.4);font-size: 12px;font-family: PingFang SC;}
    }
}
.example{margin-left:36px;height:120px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;
    img{border:1px dashed #dadada;}
}
.btn-submit{width:152px;height: 48px;line-height:48px;margin:0 auto;background: #000000;border-radius: 6px;font-size: 18px;color: #C1F421;font-weight: 500;text-align: center;cursor:pointer;}
</style>